<template>
  <div
    class="py-20 w-full
           md:pr-20 md:w-auto md:flex-grow
           lg:px-20 lg:py-0 lg:flex-grow-0"
  >
    <p class="sr-only">
      {{ $t('learn.landing.search.screen_reader_warning') }}
    </p>
    <div class="w-full">
      <Icon
        iconClass="fa-search fas"
        class="flex h-50 w-50 text-center text-grey-500 text-lg absolute items-center justify-center pointer-events-none"
      />
      <label class="sr-only" for="education-search">
        {{ $t('learn.landing.search.prompt') }}
      </label>
      <input
        id="education-search"
        v-model="searchText"
        type="text"
        :placeholder="$t('learn.landing.search.prompt')"
        class="border-grey-300 border color-black p-16 w-full h-50 pl-50 rounded outline-none focus:shadow-outline normalize-placeholder"
        autocorrect="false"
        @keyup="debouncedSearch"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import _isNull from 'lodash/isNull'
import _isEmpty from 'lodash/isEmpty'
import _noop from 'lodash/noop'
import _debounce from 'lodash/debounce'
export default {
  computed: {
    ...mapGetters('learn', [
      'isSearchLoading',
      'searchHasError',
      'searchResults',
      'searchKeywords',
      'searchedKeywords',
    ]),
    searchText: {
      get() {
        return this.searchKeywords
      },
      set(value) {
        this.updateKeywords(value)
      },
    },
    showSearchResults() {
      return (
        this.isSearchLoading ||
        this.searchHasError ||
        !_isNull(this.searchResults)
      )
    },
  },
  methods: {
    ...mapActions('learn', ['searchArticles', 'updateKeywords', 'closeSearch']),
    debouncedSearch: _debounce(function() {
      const searchTerm = this.searchKeywords.trim()

      if (_isEmpty(searchTerm)) return

      if (searchTerm === this.searchedKeywords) return

      this.searchArticles()
      this.$router.push({ name: 'search' }, null, _noop)
    }, 300),
  },
}
</script>

<style>
.normalize-placeholder::-moz-placeholder {
  opacity: 1;
  @apply text-grey-500;
}
</style>
