<template>
  <div>
    <div
      v-if="showUserArticles"
      class="p-25 pb-0 mb-50 border border-grey-300 rounded-lg"
    >
      <ListHeader
        :title="$t('learn.landing.just_for_you.title')"
        icon="eye"
        iconSet="far"
      />
      <div class="py-25 lg:p-25 lg:px-30">
        <Article
          v-for="article in nUnreadUserArticles(3)"
          :key="article.contentId"
          :article="article"
        />
        <div
          v-if="showUserArticlesMoreBtn"
          class="pb-20 lg:p-20 pt-0 flex justify-center"
        >
          <HMButton
            size="large"
            textSize="large"
            fontWeight="normal"
            class="w-full md:w-200"
            color="grey"
            :to="{ name: 'learn-articles' }"
            >{{ $t('learn.landing.view_all_btn.title') }}
          </HMButton>
        </div>
      </div>
    </div>
    <div
      v-if="showBrainExercises"
      class="p-25 pb-0 mb-50 border border-grey-300 rounded-lg"
    >
      <ListHeader
        :title="$t('learn.landing.brain_exercises.title')"
        icon="brain"
        iconSet="fa"
      />
      <div class="py-25 lg:p-25 lg:px-30">
        <BrainExercise
          v-for="brainExercise in nBrainExercises(3)"
          :key="brainExercise.exerciseCd"
          :brainExercise="brainExercise"
        />
        <div
          v-if="showBrainExercisesMoreBtn"
          class="pb-20 lg:p-20 pt-0 flex justify-center"
        >
          <HMButton
            size="large"
            textSize="large"
            fontWeight="normal"
            class="w-full md:w-200"
            color="grey"
            :to="{ name: 'learn-brain-exercises' }"
            >{{ $t('learn.landing.view_all_btn.title') }}
          </HMButton>
        </div>
      </div>
    </div>
    <div
      v-if="showHistoricalArticles"
      class="p-25 pb-0 border border-grey-300 rounded-lg"
    >
      <ListHeader :title="$t('learn.landing.history.title')" icon="history" />
      <div class="pt-25 pb-25 lg:px-30">
        <Article
          v-for="article in nHistoricalArticles(3)"
          :key="article.contentId"
          :article="article"
          type="history"
        />
        <div
          v-if="showHistoricalArticlesMoreBtn"
          class="p-20 pt-0 flex justify-center"
        >
          <HMButton
            size="large"
            textSize="large"
            fontWeight="normal"
            class="w-full md:w-200"
            color="grey"
            :to="{ name: 'learn-articles-history' }"
            >{{ $t('learn.landing.view_all_btn.title') }}
          </HMButton>
        </div>
      </div>
    </div>
    <div
      v-if="!showUserArticles"
      class="p-25 pb-0 mb-50 border border-grey-300 rounded-lg"
    >
      <ListHeader
        :title="$t('learn.landing.articles.title')"
        icon="newspaper"
        iconSet="far"
      />
      <div class="py-25 lg:p-25 lg:px-30">
        <Article
          v-for="article in randomArticles(3)"
          :key="article.contentId"
          :article="article"
        />
        <div class="pb-20 lg:p-20 pt-0 flex justify-center">
          <HMButton
            size="large"
            textSize="large"
            fontWeight="normal"
            class="w-full md:w-200"
            color="grey"
            :to="{ name: 'all-articles' }"
            >{{ $t('learn.landing.view_all_btn.title') }}
          </HMButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Article from './Article'
import BrainExercise from './BrainExercise'
import ListHeader from './ListHeader'
import { mapGetters } from 'vuex'
import _size from 'lodash/size'
import _isEmpty from 'lodash/isEmpty'
import _isNull from 'lodash/isNull'

export default {
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { Article, BrainExercise, ListHeader },
  computed: {
    ...mapGetters('learn', [
      'unreadUserArticles',
      'nUnreadUserArticles',
      'historicalArticles',
      'nHistoricalArticles',
      'randomArticles',
      'learnInitError',
      'learnInitLoading',
      'isSearchLoading',
      'searchHasError',
      'searchResults',
      'hasLoaded',
      'brainExercises',
      'nBrainExercises',
    ]),
    showSearchResults() {
      return (
        this.isSearchLoading ||
        this.searchHasError ||
        !_isNull(this.searchResults)
      )
    },
    showUserArticles() {
      return !_isEmpty(this.nUnreadUserArticles(3))
    },
    showUserArticlesMoreBtn() {
      return this.showUserArticles && _size(this.unreadUserArticles) > 3
    },
    showHistoricalArticles() {
      return !_isEmpty(this.nHistoricalArticles(3))
    },
    showHistoricalArticlesMoreBtn() {
      return this.showHistoricalArticles && _size(this.historicalArticles) > 3
    },
    showBrainExercises() {
      return !_isEmpty(this.nBrainExercises(3))
    },
    showBrainExercisesMoreBtn() {
      return this.showBrainExercises && _size(this.brainExercises) > 3
    },
  },
  created() {
    this.$emit('title', {
      text: 'learn.landing.title',
      icon: 'fa-book fas',
      iconName: 'book',
      i18n: true,
    })
  },
}
</script>
