<template>
  <div>
    <p class="pb-10 mb-20 border-b-2 border-grey-100">
      <Back
        class="py-10 inline-block"
        :route="{ name: 'learn' }"
        title="learn.back_to_learn"
      />
    </p>
    <div
      v-for="(articles, articleType) in categoryArticles"
      :key="`type-${articleType}`"
    >
      <h5 class="py-20 capitalize">{{ articleType }}</h5>
      <div>
        <Article
          v-for="article in articles"
          :key="article.contentId"
          :article="article"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Article from './Article'
import _filter from 'lodash/filter'
import _first from 'lodash/first'
import _isEmpty from 'lodash/isEmpty'
import { mapGetters } from 'vuex'
export default {
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { Article },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('learn', ['suggestedArticles', 'articlesByCategory']),
    categoryArticles() {
      return this.articlesByCategory(this.$route.params.category)
    },
  },
  updated() {
    this.updateTitle()
  },
  created() {
    this.updateTitle()
  },
  methods: {
    updateTitle() {
      //initially, this just sets the icon...
      this.$emit('title', {
        text: '',
        icon: 'fa-th fas',
        iconName: 'grid',
        i18n: false,
      })

      //once the store has been loaded...
      if (!_isEmpty(this.suggestedArticles)) {
        const categoryId = this.$route.params.category
        const category = _first(
          _filter(this.suggestedArticles.categories, { id: categoryId })
        )
        //set the title
        this.$emit('title', {
          text: category.title,
          icon: 'fa-th fas',
          iconName: 'grid',
          i18n: false,
        })
      }
    },
  },
}
</script>

<style></style>
