<template>
  <div>
    <div
      v-for="category in suggestedArticles.categories"
      :key="category.id"
      class="p-25 pb-0 mb-50 border border-grey-300 rounded-lg"
    >
      <ListHeader :title="category.title" />
      <div class="py-25 lg:p-25 lg:px-30">
        <Article
          v-for="article in topArticlesByCategory(category.id)"
          :key="article.contentId"
          :article="article"
        />
        <div
          v-if="hasMoreArticles(category.id)"
          class="pb-20 lg:p-20 pt-0 flex justify-center"
        >
          <HMButton
            size="large"
            textSize="large"
            fontWeight="normal"
            class="w-full md:w-200"
            color="grey"
            :to="{
              name: 'category',
              params: {
                category: category.id,
              },
            }"
            >{{ $t('learn.landing.view_all_btn.title') }}
          </HMButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Article from './Article'
import ListHeader from './ListHeader'
import { mapGetters } from 'vuex'
import _isNull from 'lodash/isNull'

export default {
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { Article, ListHeader },
  computed: {
    ...mapGetters('learn', [
      'suggestedArticles',
      'topArticlesByCategory',
      'hasMoreArticles',
    ]),
    showSearchResults() {
      return (
        this.isSearchLoading ||
        this.searchHasError ||
        !_isNull(this.searchResults)
      )
    },
  },
  created() {
    this.$emit('title', {
      text: 'learn.landing.title',
      icon: 'fa-book fas',
      iconName: 'book',
      i18n: true,
    })
  },
}
</script>
