<template>
    <div>
      <Spinner v-if="learnInitLoading || (loading && !!brainExerciseURL)" />
      <p class="pb-10 mb-20 border-b-2 border-grey-100">
        <Back
          class="py-10 inline-block"
          :route="{ name: 'learn' }"
          title="learn.back_to_learn"
        />
      </p>
      <article v-if="brainExerciseURL">
        <iframe
          :src="brainExerciseURL"
          class="w-full"
          style="min-height: 1200px;"
          @load="loaded"
        />
      </article>
      <p
        v-else-if="!learnInitLoading"
        class="font-bold text-lg sm:text-xl md:text-3xl text-center"
      >
        {{ $t('learn.detail.exercise_not_found') }}
      </p>
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from 'vuex'
  import Spinner from '../../common/Spinner'
  
  export default {
    components: { Spinner },
    data() {
      return {
        loading: true,
      }
    },
    computed: {
      ...mapGetters('learn', ['learnInitLoading', 'brainExerciseByCode']),
      brainExerciseURL() {
        const brainExercise = this.brainExerciseByCode(this.$route.params.exerciseCd)
        return brainExercise ? brainExercise.url : null
      },
    },
    methods: {
      ...mapActions('learn', ['brainExerciseLoaded', 'brainExerciseLoadedMarkAsPlayed']),
      loaded() {
        this.$route.params.createActivityOnLoad
          ? this.brainExerciseLoadedMarkAsPlayed({ exerciseCd: this.$route.params.exerciseCd})
          : this.brainExerciseLoaded()
        
        this.loading = false
      },
    },
  }
  </script>
  