<template>
  <p v-if="isSearchLoading">Loading</p>
  <div v-else-if="showNoResults" class="pb-10">
    <p class="pb-10 mb-30 border-b-2 border-grey-100">
      <Back
        :route="{ name: 'learn' }"
        class="py-10 inline-block"
        title="learn.back_to_learn"
        @click.native="closeSearch"
      />
    </p>
    <h3 class="mb-5">{{ $t('learn.search.no_results.title') }}</h3>
    <p class="text-grey-500 text-sm">
      {{ $t('learn.search.no_results.description') }}
    </p>
  </div>
  <div v-else>
    <p class="pb-10 mb-40 border-b-2 border-grey-100">
      <Back
        :route="{ name: 'learn' }"
        class="py-10"
        title="learn.back_to_learn"
        @click.native="closeSearch"
      />
    </p>
    <p class="pb-20">
      {{ $t('learn.search.results.title', [searchedKeywords]) }}
    </p>
    <div v-for="category in searchResults" :key="category.title">
      <h5 class="py-20 capitalize">{{ category.title }}</h5>
      <div class="py-20">
        <Article
          v-for="article in category.articles"
          :key="article.contentId"
          :article="article"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Article from './Article'
import _isEmpty from 'lodash/isEmpty'
import _isNull from 'lodash/isNull'
import { mapGetters, mapActions } from 'vuex'
export default {
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { Article },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('learn', [
      'isSearchLoading',
      'searchHasError',
      'searchResults',
      'searchKeywords',
      'searchedKeywords',
    ]),
    showNoResults() {
      return _isEmpty(this.searchResults) && !_isNull(this.searchResults)
    },
  },
  created() {
    this.$emit('title', {
      text: 'learn.search.title',
      icon: 'fa-search fas',
      iconName: 'search',
      i18n: true,
    })
  },
  methods: {
    ...mapActions('learn', ['searchArticles', 'closeSearch']),
  },
}
</script>

<style></style>
