<template>
  <article class="mb-25">
    <router-link
      class="underline hover:text-blue-500 cursor-pointer transition transition-colors duration-300"
      :to="{
        name: 'article-detail',
        params: { contentId: article.contentId },
      }"
    >
      <H4 class="mb-5">
        {{ article.title }}
      </H4>
    </router-link>
    <p class="text-grey-500 text-sm">{{ article.preview | T }}</p>
    <Tag
      v-if="isHistoricalArticle"
      :text="$t('learn.landing.history.marked_as_read')"
      icon="check"
      class="bg-black text-white inline-block"
    />
  </article>
</template>

<script>
export default {
  props: {
    article: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    isHistoricalArticle() {
      return this.type === 'history'
    },
  },
}
</script>

<style></style>