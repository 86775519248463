<template>
  <div class="w-full">
    <div
      class="w-full max-w-3xl pt-30 bg-white m-auto md:flex md:items-start md:flex-row md:flex-wrap lg:flex-no-wrap"
    >
      <div class="flex-grow md:w-full lg:w-auto lg:flex-shrink-0">
        <h1 class="capitalize h2">
          {{ title }}
        </h1>
      </div>
      <Search />
      <div
        class="w-full md:w-auto md:flex-grow lg:flex-grow-0 lg:flex-shrink-0 md:py-20 lg:py-0 relative"
      >
        <HMButton
          class="flex relative w-full items-center px-20 py-10 justify-center"
          rounded="full"
          textSize="xl"
          fontWeight="semibold"
          aria-controls="browse-categories-drawer"
          :aria-expanded="catDrawerVisible.toString()"
          @click="toggleCategoryDrawer"
        >
          {{ $t('learn.landing.browse_categories_btn.title') }}
          <Icon iconClass="fa-chevron-down fas text-base" class="ml-12" />
        </HMButton>
        <ul
          v-show="catDrawerVisible"
          id="browse-categories-drawer"
          v-click-outside="clickOutsideHandler"
          class="z-10 category-drawer absolute top-0 right-0 mt-50 p-20 bg-white rounded-lg shadow-xl flex flex-wrap justify-between md:pr-30 md:mt-80 lg:mt-60"
        >
          <li
            v-for="category in suggestedArticles.categories"
            :key="category.id"
            class="border-grey-200 border w-full bg-white rounded hover:bg-grey-100 transition-colors duration-300 md:w-1/2 mb-10 md:-mr-10"
          >
            <router-link
              class="w-full flex items-center px-20 py-6 md:py-12"
              :to="{
                name: 'category',
                params: {
                  category: category.id,
                },
              }"
              @click.native="catDrawerVisible = false"
            >
              <p class="flex-grow text-grey-500 capitalize h5">
                {{ category.title }}
              </p>
              <Icon
                class="w-20 flex justify-center"
                iconClass="fa-chevron-right fas"
              />
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="w-full bg-white py-20 md:py-25 max-w-3xl m-auto">
      <Spinner v-if="learnInitLoading" />
      <router-view @title="setTitle" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Spinner from '../../common/Spinner'
import Search from './Search'

export default {
  components: { Search, Spinner },
  data() {
    return {
      catDrawerVisible: false,
      pageTitle: 'learn.landing.title',
      pageIcon: 'fa-book fas',
      pageIconName: 'book',
      titleI18n: true,
    }
  },
  computed: {
    ...mapGetters('learn', ['learnInitLoading', 'suggestedArticles']),
    title() {
      return this.titleI18n ? this.$t(this.pageTitle) : this.pageTitle
    },
    clickOutsideHandler() {
      return {
        handler: this.closeCategoryDrawer,
        isActive: this.catDrawerVisible,
      }
    },
  },
  created() {
    this.initLearn()
  },
  methods: {
    ...mapActions('learn', ['initLearn']),
    setTitle(title) {
      this.pageTitle = title.text
      this.titleI18n = title.i18n
      this.pageIcon = title.icon
      this.pageIconName = title.iconName
    },
    toggleCategoryDrawer: function() {
      this.catDrawerVisible = !this.catDrawerVisible
    },
    closeCategoryDrawer() {
      this.catDrawerVisible = false
    },
  },
}
</script>

<style>
.category-drawer {
  width: 100%;
  @screen md {
    width: 200%;
  }
  @screen lg {
    width: 300%;
  }
}
</style>
