<template>
  <article class="mb-25">
      <router-link v-if="brainExercise.useIframe"
        class="underline hover:text-blue-500 cursor-pointer transition transition-colors duration-300"
        :to="{
          name: 'brain-exercise-detail',
          params: { exerciseCd: brainExercise.exerciseCd, createActivityOnLoad: true },
        }"
      >
      <H4 class="mb-5">
        {{ brainExercise.name }}
      </H4>
    </router-link>
    <a v-else class="underline hover:text-blue-500 cursor-pointer transition transition-colors duration-300" @click.prevent="handleLinkClick()">
      <H4 class="mb-5">
        {{ brainExercise.name }}
      </H4>
    </a>
    <p class="text-grey-500 text-sm">{{ brainExercise.description | T }}</p>
  </article>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    brainExercise: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions('learn', ['brainExerciseLoadedMarkAsPlayed']),
    handleLinkClick() {
      this.brainExerciseLoadedMarkAsPlayed({
        exerciseCd: this.brainExercise.exerciseCd,
      }).catch(error => {
        console.log('Failed to log the brain exercise as played: ' + error)
      })
        
      window.open(this.brainExercise.url, '_blank')
    },
  },
}
</script>

<style></style>