<template>
  <div>
    <Spinner v-if="learnInitLoading || (loading && !!articleURL)" />
    <p v-if="routedFromLearn" class="pb-10 mb-20 border-b-2 border-grey-100">
      <Back
        class="py-10 inline-block"
        :route="{ name: 'learn' }"
        title="learn.back_to_learn"
      />
    </p>
    <article v-if="articleURL">
      <iframe
        :src="articleURL"
        class="w-full"
        style="min-height: 640px;"
        @load="loaded"
      />
    </article>
    <p
      v-else-if="!learnInitLoading"
      class="font-bold text-lg sm:text-xl md:text-3xl text-center"
    >
      {{ $t('learn.detail.not_found') }}
    </p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Spinner from '../../common/Spinner'

export default {
  components: { Spinner },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapGetters('learn', ['learnInitLoading', 'userArticleById']),
    articleURL() {
      if(this.routedFromLearn){
        const article = this.userArticleById(this.$route.params.contentId) 
        return article ? article.url : null
      } else {
        return this.$route.params.url
      }
    },
    routedFromLearn() {
      return this.$route.path.includes('learn/article/detail')
    }
  },
  methods: {
    ...mapActions('learn', ['articleLoaded', 'articleLoadedMarkAsRead']),
    loaded() {
      this.routedFromLearn
        ? this.articleLoadedMarkAsRead(this.$route.params.contentId)
        : this.articleLoaded()
      
      this.loading = false
    },
  },
}
</script>
