<template>
    <div>
      <p class="pb-10 mb-40 border-b-2 border-grey-100">
        <Back
          class="inline-block py-10"
          :route="{ name: 'learn' }"
          title="learn.back_to_learn"
        />
      </p>
      <BrainExercise
        :brainExercise="brainExercise"
        v-for="brainExercise in brainExercises"
        :key="brainExercise.exerciseCd"
      />
    </div>
  </template>
  <script>
  import BrainExercise from './BrainExercise'
  import { mapGetters } from 'vuex'
  
  export default {
    components: { BrainExercise },
    computed: {
      ...mapGetters('learn', ['brainExercises']),
    },
    created() {
      const text = 'learn.landing.brain_exercises.title'
      const icon = 'fa-brain fas'
      const iconName = 'brain'
      this.$emit('title', { text, icon, iconName, i18n: true })
    },
  }
  </script>
  
  <style></style>
  