<template>
  <div class="flex items-center">
    <Icon
      v-if="icon"
      class="mr-10 text-center text-black text-lg"
      :title="$t(`common.icons.screen_reader_names.${icon}`)"
      :iconClass="`fa-${icon}`"
      :class="[iconSet]"
    />
    <H2 class="tracking-wide capitalize text-black">{{ title }}</H2>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    iconSet: {
      type: String,
      default: 'fas',
    },
  },
}
</script>

<style></style>
