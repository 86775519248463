<template>
  <div>
    <p class="pb-10 mb-40 border-b-2 border-grey-100">
      <Back
        class="py-10 inline-block"
        :route="{ name: 'learn' }"
        title="learn.back_to_learn"
      />
    </p>
    <Article
      v-for="article in articles"
      :key="article.contentId"
      :article="article"
      :type="type"
    />
  </div>
</template>
<script>
import Article from './Article'
import { mapGetters } from 'vuex'

export default {
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { Article },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('learn', ['unreadUserArticles', 'historicalArticles']),
    articles() {
      return this.type === 'history'
        ? this.historicalArticles
        : this.unreadUserArticles
    },
  },
  created() {
    const text =
      this.type === 'history'
        ? 'learn.landing.history.title'
        : 'learn.landing.just_for_you.title'

    const icon = this.type === 'history' ? 'fa-history fas' : 'fa-eye far'
    const iconName = this.type === 'history' ? 'history' : 'eye'
    this.$emit('title', { text, icon, iconName, i18n: true })
  },
}
</script>

<style></style>
